<template>
  <section>
    <h1 class="bold-header-text app-header">Resources</h1>
    <br />
    <b-col md="12">
      <p style="margin-top: 0.16rem">
        As an education-based accreditor, ACHC strives to be your one-stop for resources related to
        accreditation (initial and ongoing), regulatory compliance for federal and state
        requirements, and healthcare segment/industry information. To find information and documents
        relevant to ACHC recognition program(s), select the program(s) and your location(s) from the
        drop downs below.
      </p>
    </b-col>
    <div class="resource-options mt-3">
      <b-form-group label="Selected Program">
        <multiselect
          v-model="resourceSelections.programs"
          :options="groupedPrograms"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :placeholder="$t('dropdown.select-a-program')"
          label="programDescription"
          track-by="programID"
          group-values="options"
          group-label="label"
          :preselect-first="false"
          @input="submitSelections"
        >
          <template slot="option" slot-scope="props">
            <span
              v-if="props.option.$isLabel"
              class="option__title"
              :title="props.option.$groupLabel"
              >{{ props.option.$groupLabel }}</span
            >
            <span v-else class="option__title" :title="props.option.programDetailDescription">{{
              props.option.programDescription
            }}</span>
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group label="State">
        <multiselect
          v-model="resourceSelections.states"
          :options="states"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :placeholder="$t('dropdown.select-a-state')"
          label="stateName"
          track-by="stateID"
          group-values="options"
          group-label="label"
          :preselect-first="false"
          @input="submitSelections"
        ></multiselect>
      </b-form-group>
    </div>
    <achc-collapsible-card
      v-for="(programGroup, index) in resources"
      :key="`${programGroup.program.replace(/\s/g, '')}-${index}`"
      :card-visible="programGroup.visible"
    >
      <template slot="header">
        <h2 class="resource-program-header">{{ programGroup.program }}</h2>
      </template>
      <template slot="body">
        <div
          class="category-resources row"
          v-for="category in programGroup.categories"
          :key="`${programGroup.program.replace(/\s/g, '')}-${category.category.replace(
            /\s/g,
            ''
          )}`"
        >
          <h3 class="resource-category-header">
            {{ category.category }}
          </h3>
          <div class="container">
            <template v-for="resource in category.resource">
              <div
                :key="`${programGroup.program.replace(/\s/g, '')}-${category.category.replace(
                  /\s/g,
                  ''
                )}-${resource.resourceID}`"
              >
                <ul>
                  <li>
                    <a
                      v-if="resource.isDownload"
                      class="resource-link"
                      href="javascript:void(0)"
                      :title="resource.title"
                      v-on:click="($event) => downloadResource(resource)"
                    >
                      {{ resource.title }}
                    </a>
                    <a
                      v-else
                      class="resource-link"
                      :href="getResourceURL(resource)"
                      target="_blank"
                      :title="resource.title"
                    >
                      {{ resource.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="resourceSelections.states != 0 && !programGroup.hasStateResources"
          class="resources-no-state"
        >
          No State Specific Resources
        </div>
      </template>
    </achc-collapsible-card>
    <div v-if="emptyStateVisible" class="resources-empty-state">
      No resources available based on current selections
    </div>
  </section>
</template>
<script>
import CollapsibleCard from "../../components/Common/CollapsibleCard.vue";
import Multiselect from "vue-multiselect";
import { bus } from "../../main";

import CompanyMixin from "../../mixins/CompanyMixin.vue";
import ProgramMixin from "../../mixins/ProgramMixin.vue";
import ResourceMixin from "../../mixins/ResourceMixin.vue";
import StateMixin from "../../mixins/StateMixin.vue";
import DirtyDataMixin from "../../mixins/DirtyDataMixin.vue";

import { WebAPIUrl } from "../../constants/config";

export default {
  props: ["companyID"],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.company = JSON.parse(localStorage.getItem("currentCompany"));
    await this.getSelectedProgramsByCompany(this.company.companyID);
    this.programSelections = this.selectedProgramsByCompany;
    this.resourceSelections.programs = this.selectedProgramsByCompany;

    await this.getAllProgramsByType();
    bus.$on("dirtyModalSaveChanges", (to) => {
      this.saveProgramsOfInterest(to);
    });
    this.initDirtyDataWatcher("resourceSelections.programs");
    await this.getStatesGroupedByType();

    if (this.selectedProgramsByCompany.length > 0) {
      await this.submitSelections();
    }
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  destroyed() {
    bus.$off("dirtyModalSaveChanges");
  },
  data() {
    return {
      controlledDocUrl: `${WebAPIUrl}/api/navigator-resource/controlled-doc`,
      resourceSelections: {
        programs: [],
        states: [],
      },
    };
  },
  mixins: [CompanyMixin, ProgramMixin, ResourceMixin, StateMixin, DirtyDataMixin],
  components: {
    "achc-collapsible-card": CollapsibleCard,
    Multiselect,
  },
  methods: {
    async submitSelections() {
      const { programs = [], states = [] } = this.resourceSelections;
      //Moved from Resource mixin because making this as a common end point
      const categories = "1,2,3,4,5,6,7,19";
      await this.getResources(categories, programs, states);
    },
    getResourceURL(resource) {
      if (resource.controlledDocID === null) {
        return resource.url;
      }
      const contId = resource.controlledDocID.trim();
      return `${this.controlledDocUrl}/${contId}`;
    },
    async downloadResource(resource) {
      const url = this.getResourceURL(resource);
      const extension = resource.fileExtension || "pdf";
      await this.downloadResourceFile(url, `${resource.controlledDocID}.${extension}`);
    },
    async saveProgramsOfInterest(to) {
      this.company = JSON.parse(localStorage.getItem("currentCompany"));
      await this.updateSelectedPrograms(
        this.company.companyID,
        this.resourceSelections.programs,
        null
      );
      this.setPreserveMessageAfterPush(true);
      this.$router.push(to.fullPath);
    },
  },
  computed: {
    emptyStateVisible() {
      return (
        this.resources.length <= 0 &&
        (this.resourceSelections.programs.length > 0 || this.resourceSelections.states.length > 0)
      );
    },
  },
};
</script>
